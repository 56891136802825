import $http from './request.js'
import axios from 'axios'
// import Qs from 'qs'
const newRequest = axios.create() //图片
export const upload_img = async (formdata) => {
  let img_url = await newRequest({
    method: 'POST',
    url: 'https://www.5zixi.com/api/upload',
    data: formdata,
  })
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      return err
    })
  return img_url
}
//发布问问
// export const issue_question = (id, content, img_url, anonymous, share) =>
//   $http({
//     url: `issuez_coffeesay?user_id=${id}&content=${content}&imgurls=${img_url}&anonymous=${anonymous}&share=${(share = '' ? (share = 0) : share)}`,
//   })
//获取问问
export const get_question = (id, pageSize, currentPage) =>
  $http({
    url: `get_questions?user_id=${id}&pageSize=${pageSize}&currentPage=${currentPage}`,
  })
//获取我的资料
export const get_user_infor = (id) =>
  $http({
    url: `get_user_infor?user_id=${id}`,
  })
//获取我的钱包
export const get_user_moeny = (id) =>
  $http({
    url: `get_user_moeny?user_id=${id}`,
  })
//获取我的差评数get_user_negative_comment
export const get_user_negative_comment = (id) =>
  $http({
    url: `get_user_negative_comment?user_id=${id}`,
  })
//补缴成功后修改
export const set_payment = (id) =>
  $http({
    url: `set_payment?id=${id}`,
  })
//修改个人资料
export const updata_user_infor = (id, content, key) =>
  $http({
    url: `updata_user_infor?user_id=${id}&${key}=${content}`,
  })
export const get_oquestion = (coffeeid) =>
  $http({
    url: `oget_questions?coffee_id=${coffeeid}`,
  })
export const get_answers = (coffeeid) =>
  $http({
    url: `get_answers?coffer_id=${coffeeid}`,
  })
//修改登录时间
export const set_login_number = (user_id) =>
  $http({
    url: `set_login_number?user_id=${user_id}`,
  })
export const add_answer = (user_id, coffeeid, content, anonymity) =>
  $http({
    url: `add_answer?user_id=${user_id}&coffer_id=${coffeeid}&content=${content}&anonymity=${anonymity}`,
  })
export const login = (mobile, password) =>
  $http({
    method: 'post',
    url: `login?mobile=${mobile}&password=${password}`,
  })
//获取办办
export const get_act = (page_num) =>
  $http({
    url: `/get_act?pageSize=5&currentPage=${page_num}`,
  })
//发布办办
export const add_atc = (user_id, subject, address, lable, scale, join_date, end_date, image_url, lat, lon, share) =>
  $http({
    url: `/add_atc?user_id=${user_id}&subject=${subject},&address=${address},&lable=${lable},&scale=${scale},&join_date=${join_date},&end_date=${end_date},&image_url=${image_url},&lat=${lat},&lon=${lon},&share=${share}`,
  })
//设置差评
export const set_negative_comment = (id, checked) =>
  $http({
    url: `/set_negative_comment?id=${id}&checked=${checked}`,
  })
//查询差评
export const get_negative_comment = (id) =>
  $http({
    url: `/set_negative_comment?user__id=${id}`,
  })
export const get_peoples = (lat, lon, user_dis, user_rows, pages) =>
  $http({
    url: `/get_peoples?user_id=${52322}&lat=${lat}&lon=${lon}&dis=${user_dis}&currentPage=${pages}&pageSize=${user_rows}`,
  })
export const getmyFriends = (user_id) =>
  $http({
    url: `/get_myfriends?user_id=${user_id}`,
  })
export const get_my_moeny = (user_id) =>
  $http({
    url: `/get_my_moeny?user_id=${user_id}`,
  })
export const get_my_coffee = (id) =>
  $http({
    url: `/get_users_question?user_rows=${id}`,
  })
export const add_my_can_answer = (id, answer_id) =>
  $http({
    //添加我可以查看的答案
    url: `/add_my_can_answer?user_id=${id}&answer_id=${answer_id}`,
  })
export const get_my_can_answer = (id) =>
  $http({
    //我可以查看的答案
    url: `/get_my_can_answer?user_id=${id}`,
  })
export const add_user_moeny = (user_id, add_type, num) =>
  $http({
    //添加有空币
    url: `/add_user_moeny?user_id=${user_id}&add_type='${add_type}'&num=${num}`,
  })
export const add_member = (mobile, password, user_name, grade, company_name, job_title, actual_name) =>
  $http({
    url: `add_members?mobile=${mobile}&user_name=${user_name}&password=${password}&grade=${grade}&company_name=${company_name}&job_title=${job_title}&actual_name=${actual_name}`,
  })
export const get_myquestion = (user_id) =>
  $http({
    url: `get_myQuestions?user_id=${user_id}`,
  })
export const get_my_answers = (user_id) =>
  $http({
    url: `get_my_answers?user_id=${user_id}`,
  })
export const delete_my_question = (id) =>
  $http({
    //删除问问
    url: `delete_myQuestions?question_id=${id}`,
  })
export const delete_my_answer = (id) =>
  $http({
    //删除回答
    url: `delete_myAnswer?answer_id=${id}`,
  })
export const Send_money = (id, user_id) =>
  $http({
    //发放赏金
    url: `SendMoney?coffee_id=${id}&user_id=${user_id}`,
  })
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      return err
    })
export const add_Ranking = (question_id, answer_id, value) =>
  $http({
    //排名
    url: `add_Ranking?question_id=${question_id}&answer_id=${answer_id}&value=${value}`,
  })
//封装微信登录接口
export const wxLogin = (url) => {
  let redirect_uri = encodeURIComponent(url)
  let appid = 'wxf07a2aadb806a199'
  window.location.href =
    // `https://www.5zixi.com/get-weixin-code.html?appid=${appid}&scope=snsapi_base&state=hello-world&redirect_uri=${redirect_uri}`//旧版本获取code或出现错误
    `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_userinfo&state=login#wechat_redirect`
}
export const up_question = (coffeeid, money) =>
  $http({
    url: `up_Question?coffeeid=${coffeeid}&money=${money}`,
  })

// 获取url上的参数
export const getUrlParam = (name) => {
  let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)') //构造一个含有目标参数的正则表达式对象
  let r = window.location.search.substr(1).match(reg) //匹配目标参数
  if (r != null) return decodeURIComponent(r[2])
  return null //返回参数值
}
//微信登录
export const wx_login = (code) =>
  $http({
    url: `https://www.5zixi.com/api/oauth/wx_login?code=${code.match(/=(\S*)&/)[1]}`,
  })
//微信支付
export const wx_pay = (code, money) =>
  $http({
    url: `https://www.5zixi.com/api/wx_pay/get_open_id?code=${code.match(/=(\S*)&/)[1]}&money=${money * 100}`,
  })
//微信分享
export const getWxSignature = () =>
  $http({
    url: `https://www.5zixi.com/api/wx_share?url=${encodeURIComponent(location.href.split('#')[0])}`,
  })
