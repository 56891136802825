<template>
  <div class="all">
    <van-overlay :show="show" @click="show = false">
      <div class="wrapper" @click.stop></div>
    </van-overlay>
    <van-dialog v-model="show" title="查看测试结果" message="请支付200有空币查看结果" show-cancel-button confirmButtonText="支付查看" cancelButtonText="分享查看" cancelButtonColor="#1097e6" @confirm="goto()" @cancel="close()">
      <!-- <p style="width: 90%; margin: 10px auto; font-size: 14px; text-indent: 30px">请支付200有空币查看结果</p> -->
      <!-- 点击确定进入支付页面，支付成功后方可查看结果，或者您可以选择分享给朋友来查看结果。 -->
      <!-- <van-field name="switch" label="公开结果">
        <template #input>
          <van-switch v-model="switchChecked" size="20" />
        </template>
      </van-field> -->
    </van-dialog>
    <van-dialog v-model="show1" message="点击右上角三个点来分享到朋友圈，以获取您的测试结果。" @confirm="goto1()"> </van-dialog>

    <!-- <div class="head" >
      <div class="title title1">{{ data.name }}</div>
      <div class="paragraph">{{ data.name2 }}</div>
    </div>
    <div class="container">
      <div class="title" style="font-weight: 500">评测结果:</div>
      <div :style="`color:${character[name].color}`">{{ character[name].title }}</div>
      <div class="icon-con" :style="`color:${character[name].color}`">
        <i :class="computedFont"></i>
      </div>
      <div class="title" style="font-size: 3.5vw">性格描述：</div>
      <div class="text color-background">{{ character[name].text }}</div>
    </div>
    <div class="advocate"></div>
    <van-button plain type="info" @click="go_question()">返回首页</van-button> -->
  </div>
</template>

<script>
// import TableVant from './TableVant.vue'
import {Notify} from 'vant'
import {add_user_moeny, get_my_moeny, updata_user_infor} from '../api/getData'
export default {
  // components: {TableVant},
  data() {
    return {
      data: [],
      user_id: window.$wujie?.props, // {data: xxx, methods: xxx}
      show: true,
      show1: false,
      name: 'red',
      switchChecked: true,
    }
  },
  computed: {
    computedFont() {
      let fontClass = ''
      switch (this.name) {
        case 'red':
          fontClass = 'font-icon-benpao'
          break
        case 'blue':
          fontClass = 'font-icon-sikao'
          break
        case 'yellow':
          fontClass = 'font-icon-bumenlingdao'
          break
        case 'green':
          fontClass = 'font-icon-heping'
          break
      }
      return fontClass
    },
  },
  mounted() {
    console.log(this.$route.query.data)
    window.$wujie?.bus.$emit('data', this.$route.query.data)
  },
  methods: {
    go_question() {
      window.$wujie?.props.jump({path: '/'})
    },
    close() {
      this.show1 = true
      // console.log(this.user_id.user_id)
      // Dialog({message: '点击右上角三个点来分享到朋友圈，以获取您的测试结果。'})
      // this.$router.push('/Questions')
      // console.log('有空币不足')
      // Notify({type: 'warning', message: '您的有空币不足，请您充值！'})
      // this.$router.push('http://192.168.0.188:8080/ykb/' + this.user_id.user_id)
      // window.$wujie?.props.jump({path: '/'})
    },
    goto1() {
      // window.$wujie?.props.jump({path: '/'})
    },
    async goto() {
      let that = this

      // this.$store.state.user_infor.user_id
      console.log(that.user_id)
      get_my_moeny(that.user_id.user_id).then((res) => {
        if (res.ykb != null && res.ykb > 200) {
          add_user_moeny(that.user_id.user_id, 'color', 200)
          Notify({type: 'success', message: '支付成功'})
          updata_user_infor(that.user_id.user_id, that.name, 'color').then((res) => {
            console.log(res)
            updata_user_infor(that.user_id.user_id, '1', 'see_color').then((res) => {
              console.log(res)
              window.$wujie?.props.jump({path: '/pagesA/infors/infors?id=' + that.user_id.user_id})
            })
            // console.log(res)
          })
        } else {
          //seecolor 0 无数据 1 以支付有数据 2 未支付有数据
          console.log('有空币不足')
          Notify({type: 'warning', message: '您的有空币不足，请您充值！'})
          // this.$router.push('http://192.168.0.188:8080/ykb/' + this.user_id.user_id)
          updata_user_infor(that.user_id.user_id, that.name, 'color').then((res) => {
            console.log(res)
            updata_user_infor(that.user_id.user_id, '2', 'see_color').then((res) => {
              console.log(res)
              window.$wujie?.props.jump({path: '/pagesB/pay/pay1'}, `?color=${window.encodeURIComponent('/color')}`)
            })
          })
        }
      })
    },
    colorComupted(array) {
      let red, blue, yellow, green
      red = blue = yellow = green = 0
      array.some((item, index) => {
        if (index <= 15) {
          if (item === 'A') {
            red += 1
          }
          if (item === 'B') {
            blue += 1
          }
          if (item === 'C') {
            yellow += 1
          }
          if (item === 'D') {
            green += 1
          }
        } else {
          if (item === 'D') {
            red += 1
          }
          if (item === 'C') {
            blue += 1
          }
          if (item === 'B') {
            yellow += 1
          }
          if (item === 'A') {
            green += 1
          }
        }
      })
      //比较大小
      console.log(red, blue, yellow, green)
      let name = '',
        num = 0
      if (num < red) {
        num = red
        name = 'red'
      }
      if (num < blue) {
        num = blue
        name = 'blue'
      }
      if (num < yellow) {
        num = yellow
        name = 'yellow'
      }
      if (num < green) {
        num = green
        name = 'green'
      }
      return name
    },
  },
  created() {
    // 用户结果
    let that = this
    let data = this.$route.query.data
    window.$wujie?.bus.$emit('data', this.$route.query.data)
    console.log(data.length)
    updata_user_infor(that.user_id.user_id, data, 'app1').then((res) => {
      console.log(res)
    })

    data = data.split(',')
    this.name = this.colorComupted(data)
    // 获取颜色人格的描述数据
    this.$store.state.questionBase.some((item) => {
      if (item.key === 'Personality_color_test') {
        this.data = item
      }
    })
    window.wx.miniProgram.postMessage({name: this.name})
  },
}
</script>

<style>
.all {
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgb(167, 185, 213);
  flex-direction: column;
  text-align: left;
}
.head {
  text-align: left;
  padding: 2vw 5vw;
}
.head div {
  color: white !important;
  margin: 0;
}
.container {
  height: 74.6vh;
  flex: 1;
  overflow: auto;
  background-color: white;
  border-radius: 3vw 3vw 0 0;
  padding: 7vw 5vw;
  font-size: 3.2vw;
}
.advocate {
  position: fixed;
}
.title {
  font-weight: 600;
  font-size: 4vw;
  color: rgb(22, 22, 22);
  margin: 0.8vh 0;
}
.title1 {
  font-size: 3.5vw;
}
.paragraph {
  line-height: 5.4vw;
  color: rgb(136, 136, 136);
  margin: 0.2vh 0;
  font-size: 3vw;
}
.icon-con {
  text-align: center;
  margin: 10vw 0;
}
.icon-con i {
  font-size: 30vw;
}
.text {
  font-size: 3vw;
  line-height: 5vw;
  padding: 0 5vw;
}
.color-background {
  background-color: rgb(250, 250, 250);
  border-radius: 10px;
  box-shadow: 0 0 3px 0px rgba(0, 0, 0, 0.05);
  padding: 4.48vw;
  flex: 1;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  width: 120px;
  height: 120px;
  background-color: #fff;
}
</style>
