// import store from '../store/index.js'
import axios from 'axios'
// import router from '../router/index.js'
// import {Notify} from 'vant'

const $http = axios.create({
  baseURL: 'https://www.5zixi.com/api/',
  // baseURL: 'http://localhost:8081/api/',
  timeout: 10000, // request timeout
})
// 发起请求之前的拦截器
$http.interceptors.request.use(
  (config) => {
    // store.commit('showLoading')
    // store.state.Loading = true
    // const token = localStorage.getItem('token')
    // if (token) config.headers.common['Authorization'] = 'Bearer ' + token
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)
// 响应拦截器
$http.interceptors.response.use(
  (response) => {
    // store.commit('hideLoading')
    if (response.status !== 200) return Promise.reject(new Error(response.data.message || 'Error'))
    return response.data
  },
  (error) => {
    // store.commit('hideLoading')
    //401重新登录
    if (error.response.status == 401) {
      localStorage.removeItem('token')
      //   Notify({type: 'warning', message: '登录已过期，请重新登录'})
      //   router.replace({
      //     path: '/login',
      //     query: {redirect: router.currentRoute.fullPath},
      //   })
    }
    return Promise.reject(error.response)
  }
)
export default $http
